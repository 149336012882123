<template>
	<div>
		<aq-task-notification-modal
			v-for="(task, index) in notificationTasks"
			:key="task.claimId"
			:id="task.claimId"
			:index="index"
			:name="`task-notification-modal-${task.claimId}`"
			:timer="openedDateElapsedTime(task.reassessmentDateTime ?? task.createdDate)"
			:payee-types="task.payeeTypes"
			:form-type="task.formType"
			:source="task.claimSource"
			@close="onClose"
		/>
	</div>
</template>

<script>
import { timeBetweenFormatted } from '@commonServices/utils/dateUtils';
import { claimSourceDisplayMap } from '@clientCommon/services/models/ClaimSource';
import { ClaimFormTypeDisplayMap } from '@clientCommon/services/models/ClaimFormType';
import { claimIdFormat } from '@commonServices/utils/filters';
import { changePageIcon, PageIconType } from '@commonServices/utils/domUtils';
import AqTaskNotificationModal from './AqTaskNotificationModal';
import { mapGetters, mapState, mapActions } from 'vuex';
import eventBus from '@commonServices/eventBus';

const intervalWorker = new Worker(new URL('@workers/intervalWorker.js', import.meta.url));

export default {
	name: 'AqTaskNotificationContainer',
	components: {
		AqTaskNotificationModal,
	},
	props: {
		hidden: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			currentTime: new Date(),
		};
	},
	computed: {
		...mapState(['currentUser']),
		...mapGetters(['autoAssignedTasks']),
		notificationTasks () {
			return this.autoAssignedTasks
				.filter(x => x.assignmentAcknowledgedDate === null)
				.sort((first, second) => {
					const firstDate = first.reassessmentDateTime ?? first.createdDate;
					const secondDate = second.reassessmentDateTime ?? second.createdDate;
					return firstDate > secondDate ? -1 : 1;
				});
		},
	},
	watch: {
		autoAssignedTasks () {
			this.showTaskNotificationModals();
			this.updatePageIcon();
		},
	},
	created () {
		const self = this;
		intervalWorker.onmessage = (e) => {
			const { id, command } = e.data;
			if (id === self._uid && command === 'timer-tick') {
				self.currentTime = new Date();
			}
		};
		intervalWorker.postMessage({
			id: self._uid,
			command: 'timer-start',
			interval: 1,
		});
	},
	mounted () {
		eventBus.$on('task-acknowledged', this.onTaskAcknowledged);
		eventBus.$on('task-assigned', this.showBrowserNotification);
	},
	beforeDestroy () {
		eventBus.$off('task-acknowledged', this.onTaskAcknowledged);
		eventBus.$off('task-assigned', this.showBrowserNotification);
	},
	methods: {
		...mapActions(['changeTaskAcknowledgedDate', 'acknowledgeTask']),
		openedDateElapsedTime (time) {
			return timeBetweenFormatted(time, this.currentTime);
		},
		async showTaskNotificationModals () {
			if (this.hidden) {
				return;
			}

			// Allows for DOM to render modals before shown is called
			await this.$nextTick();
			for (const task of this.notificationTasks) {
				this.$modal.show(`task-notification-modal-${task.claimId}`);
			}
		},
		updatePageIcon () {
			const icon = this.notificationTasks.length ? PageIconType.Bell : PageIconType.Default;
			changePageIcon(icon);
		},
		acknowledgeAll () {
			this.notificationTasks.map(x => x.claimId).forEach(claimId => {
				this.onClose(claimId);
			});
		},
		onClose (claimId) {
			// Close modal & Acknowledge
			this.hide(claimId);
			this.acknowledgeTask(claimId);
		},
		onTaskAcknowledged (payload) {
			if (this.currentUser?.id === payload.userId) {
				this.hide(payload.claimId);
				this.changeTaskAcknowledgedDate(payload);
			}
		},
		hide (claimId) {
			this.$modal.hide(`task-notification-modal-${claimId}`);
		},
		showBrowserNotification (task) {
			if (this.currentUser?.id === task.userId && task.autoAssigned && !this.hidden) {
				// if browser does not support desktop notifications we skip it
				if (!('Notification' in window)) return;

				if (Notification.permission === 'granted') {
					this.createBrowserNotification(task);
				} else if (Notification.permission !== 'denied') {
					// else ee need to ask the user for permission
					Notification.requestPermission().then((permission) => {
						// if the user accepts, let's create a notification
						if (permission === 'granted') {
							this.createBrowserNotification(task);
						}
					});
				}
			}
		},
		createBrowserNotification (task) {
			const options = {
				body: `Claim: ${claimIdFormat(task.claimId)}, Source: ${claimSourceDisplayMap[task.claimSource]}, Type: ${ClaimFormTypeDisplayMap[task.claimFormType]}`,
				icon: require('@/assets/icons/TaskNotificationBrowserBell.svg'),
				requireInteraction: true,
				tag: task.claimId,
			};
			const notification = new Notification('You have had a claim auto-assigned to you in Vision', options);

			let navigateUrl = this.$router.resolve({ name: 'navigate', params: { subject: 'claim', identifier: task.claimId } });
			navigateUrl = `${window.location.origin}${navigateUrl.href}`;

			notification.onclick = () => {
				window.open(navigateUrl, '_blank');
				notification.close();
			};
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .task-modal {
  overflow: visible;
  pointer-events: all;
}

.scale-up-enter-active {
  animation: scale-up 1s ease-in-out;
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.background {
  overflow: visible;
  z-index: -1;
  position: absolute;
  top: -29px;
  left: -29px;
  width: 320px;
  height: 220px;
}

.bell {
  .ring {
    position: absolute;
    left: 14.2px;
    top: 14px;
  }

  .background {
    position: absolute;
    top: 7px;
    left: 8px;
  }

  .icon {
    position: absolute;
    color: #fff;
    top: 15px;
    left: 15.5px;
    transition: transform 0.1s ease-in-out; /* Smooth transition */
    transform-origin: 50% 50%; /* Rotate around the center */

    &:hover {
      transform: rotate(15deg);
    }
  }
}

.modal-container {
  margin-left: 24px;

  .title {
    color: var(--taskNotificationTextColour);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    height: 55px;
    padding-left: 32px;
    padding-right: 5px;
    margin-right: 6px;
    padding-top: 15px;
  }

  .body {
    text-align: center;
    width: 100%;
    height: 100px;
    margin-top: 5px;
    padding: 5px 10px;
  }
}

.timer {
  label {
    vertical-align: super;
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
  }
}

.btn-acknowledge {
  background: #FFBB81;
  color: #012336;

  &:hover {
    background: #FE7602;
    color: #FFF;
  }
}

.pipe {
  color: #FE7602;
}
</style>
